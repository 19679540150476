import React from 'react';

// === Content === //
import { hcpHonestyPopupData } from '@graphql/HcpHonestyPopup';

// === Components === //
import VideoCover from 'react-video-cover';
import Container from '@components/common/Container';

// === Helpers === //
import Cookies from 'js-cookie';
import * as utils from '@utils';

// === Styles === //
import styled from '@emotion/styled';
import { css } from '@emotion/react';
import { breakpoints, colors, fluidRange, mixins, respondFrom, respondTo } from '@styles';

// === Types === //
import { HomepageHeroData } from '@content/types/homepage';
import { HonestyPopupData, PageType } from '@content/types/layout';
import { StartPageButtonsData, StartPageLogoData } from '@content/types/startPage';

interface HeroVideoProps {
  data: HomepageHeroData;
  pageType: PageType;
  buttonsData?: StartPageButtonsData[];
  logo?: StartPageLogoData;
  handleModalOpen?: Function;
}

const HeroVideoWrapper = styled.div`
  position: relative;
  height: 305px;

  ${respondFrom(
    breakpoints.md,
    css`
      height: 450px;
    `
  )}
  ${respondFrom(
    breakpoints.lg,
    css`
      height: 547px;
    `
  )}

  &.page-type--start_page {
    border-top: 25px solid ${colors.ui.patientDefault};
    border-bottom: 25px solid ${colors.ui.hcpDefault};
    height: 619px;

    ${respondFrom(
      breakpoints.md,
      css`
        height: 619px;
      `
    )}
    ${respondFrom(
      breakpoints.lg,
      css`
        height: 619px;
      `
    )}
  }
`;

const HeroVideoContent = styled.div`
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  margin: auto;
`;

const HeroVideoContentInner = styled.div`
  position: absolute;
  bottom: 70px;
  padding-left: 80px;

  ${respondTo(
    breakpoints.md,
    css`
      bottom: 125px;
      padding-left: 20px;
    `
  )}

  &.page-type--start_page {
    position: static;
    bottom: 55px;
    padding-left: 0;
    width: 100%;
    margin: auto;
    left: 0;
    right: 0;
    overflow: hidden;

    h1 {
      ${respondFrom(
        breakpoints.lg,
        css`
          transform: translateX(22vw);
        `
      )}
    }
  }
`;

const H1 = styled.h1`
  color: ${colors.white};

  span {
    display: block;
    text-shadow: 0 6px 15px rgba(0, 0, 0, 0.69);

    &:nth-of-type(2) {
      padding-left: 85px;

      ${respondTo(
        breakpoints.md,
        css`
          padding-left: 40px;
        `
      )}
    }
    &:nth-of-type(3) {
      font-weight: 500;
      text-shadow: none;
    }
  }
`;

const ButtonLinksWrapper = styled.div`
  display: flex;
  flex-flow: column;
  align-items: center;
  position: absolute;
  bottom: 15px;
  left: 0;
  right: 0;

  ${respondFrom(
    breakpoints.md,
    css`
      flex-flow: row;
      justify-content: center;
    `
  )}

  ${respondFrom(
    breakpoints.lg,
    css`
      bottom: 55px;
    `
  )}
`;

const ButtonLink = styled.a`
  display: inline-block;
  border: 4px solid ${colors.white};
  border-radius: 20px;
  max-width: 396px;
  color: ${colors.white};
  text-shadow: 0 6px 15px rgba(0, 0, 0, 0.68);
  font-weight: 700;
  background-color: ${colors.ui.patientDefaultTransparent};
  ${fluidRange('fontSize', '28px', '40px')};
  ${fluidRange('lineHeight', '30px', '38px')};
  padding: 25px;
  text-align: center;
  text-decoration: none;
  ${mixins.transitionDefault};
  margin: 0 45px;

  &.type--1 {
    background-color: ${colors.ui.hcpDefaultTransparent};

    &:hover {
      ${respondFrom(
        breakpoints.lg,
        css`
          background-color: ${colors.ui.hcpDefault};
        `
      )}
    }
  }

  &:hover {
    ${respondFrom(
      breakpoints.lg,
      css`
        background-color: ${colors.ui.patientDefault};
      `
    )}
  }

  ${respondTo(
    breakpoints.lg,
    css`
      padding: 10px;
      max-width: 298px;
      margin: 10px 25px;
    `
  )}
`;

const LogoWrapper = styled.div`
  width: 300px;
  height: 145px;
  margin-bottom: 40px;
  margin-top: 15px;

  ${respondTo(
    breakpoints.lg,
    css`
      width: 227px;
      height: 110px;
    `
  )}
`;

const HeroVideo = ({ data, pageType, buttonsData, logo, handleModalOpen }: HeroVideoProps) => {
  const honestyPopupData: HonestyPopupData = hcpHonestyPopupData();
  const honestyPopupCookies = Cookies.get('rethinkFabry-honesty');

  return (
    <HeroVideoWrapper className={`page-type--${pageType}`}>
      {data.video && (
        <VideoCover
          videoOptions={{
            src: data.video,
            autoPlay: true,
            muted: true,
            loop: true,
            playsInline: true,
          }}
          remeasureOnWindowResize
        />
      )}
      <HeroVideoContent>
        <Container>
          <HeroVideoContentInner className={`page-type--${pageType}`}>
            {pageType === 'start_page' && logo && logo.src && (
              <LogoWrapper>
                <img src={logo.src} alt={logo.alt} title={logo.title} />
              </LogoWrapper>
            )}

            <H1>{utils.SafeHtml(data.header)}</H1>

            {pageType === 'start_page' && buttonsData && buttonsData.length > 0 && (
              <ButtonLinksWrapper>
                {buttonsData.map((btn, index) => (
                  <ButtonLink
                    key={index}
                    href={btn.url}
                    className={`type--${index} hero-button`}
                    onClick={() => {
                      if (!honestyPopupData.active || honestyPopupCookies === 'true') {
                        Cookies.set('rethinkFabry-view', index === 0 ? 'patients' : 'hcp', {
                          expires: 365,
                        });
                      } else {
                        if (index === 0)
                          Cookies.set('rethinkFabry-view', 'patients', { expires: 365 });
                      }
                    }}
                  >
                    {btn.label}
                  </ButtonLink>
                ))}
              </ButtonLinksWrapper>
            )}
          </HeroVideoContentInner>
        </Container>
      </HeroVideoContent>
    </HeroVideoWrapper>
  );
};

export default HeroVideo;
